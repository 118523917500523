<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama Bank"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="account_no" label="No Rekening"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="branch" label="Cabang"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="on_behalf" label="Atas Nama"></form-input>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <b-form-checkbox v-model="is_main" switch>Jadikan Akun Utama</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'banks'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import {BFormGroup,BFormCheckbox} from 'bootstrap-vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    BFormGroup,
    BFormCheckbox
  },
  data(){
    return {
      id: this.$route.params.id||null,
      name:'',
      code:'',
      is_main: false,
      account_no:'',
      branch:'',
      on_behalf:'',
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'banks/store',
      dispatchShow: 'banks/show',
      dispatchUpdate: 'banks/update',
    }),
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.is_main = data.is_main ? true : false
      this.account_no = data.account_no
      this.branch = data.branch
      this.on_behalf = data.on_behalf
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,name,account_no,branch,on_behalf} = this
      let params = {id,code,name,account_no,branch,on_behalf}
      params.is_main = this.is_main ? 1 : 0
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'banks'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>